@import '@/styles/artifacts.scss';

































































































































.audit-view {
  width: 100%;
}
