@import '@/styles/artifacts.scss';



































































































































































































































































































































































.project-items-table {
  min-height: 63dvh;
}
