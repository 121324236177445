@import '@/styles/artifacts.scss';












































































































.setting-measurement {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: colorVodafone(dark-grey);
  padding: 15px;

  &__title {
    min-height: 105px;
    font-size: fontSize(fs-150);
    text-align: center;
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    min-height: 200px;
    max-height: 200px;
    box-sizing: border-box;
    border: 7px solid;
    border-color: colorVodafone(light-grey);
    border-radius: 50%;

    ::v-deep .html {
      text-align: left;

      p {
        margin: 0;
      }
    }

    ::v-deep .ui-input-switch {
      flex-direction: column;
      justify-content: center;
    }

    ::v-deep input:checked + .knob::before {
      transform: translateX(1.5em);
    }

    ::v-deep .knob {
      height: 2em;
      width: 3.5em;
      border-radius: 2em;

      &:before {
        height: 1.7em;
        width: 1.7em;
      }

      &:after {
        height: 1.9em;
        width: 1.9em;
        left: 0;
        top: 0;
      }
    }

    &__text {
      font-size: fontSize(fs-150);
    }

    &__value {
      font-size: fontSize(fs-200);
      font-weight: fontWeight(regular-bold);
    }
  }
}
