@import '@/styles/artifacts.scss';




















































































.project-list-container {
  max-height: 60dvh;
  min-height: 60dvh;
}
.status-cell {
  font-weight: bolder;
}
.previous-value {
  color: #d9534f; /* Red color for old values */
  text-decoration: line-through; /* Strikethrough for old values */
}

.updated-value {
  color: #5cb85c; /* Green color for new values */
}

.no-change {
  font-style: italic; /* Style for empty or null values */
  color: #6c757d; /* Gray color for no changes */
}
