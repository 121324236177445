@import '@/styles/artifacts.scss';










































































































































































































































































































































































































































































































.column-header {
  margin-top: 0;
  margin-bottom: 2px;
}
.input-column {
  flex-grow: 1;
}
.tab-margin {
  margin: 10px;
}
.fieldset-padding {
  padding-top: 10px;
}
fieldset {
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 10px 14px 12px;
  margin-bottom: 20px;
  legend {
    font-weight: bold;
    color: #333;
    font-size: 14px;
  }
  .label {
    font-size: 14px;
  }
  .cell {
    p {
      margin: 6px 0;
      font-size: 14px;
    }
  }
}
