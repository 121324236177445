@import '@/styles/artifacts.scss';





































































































































































































.column-header {
  margin-top: 0;
  margin-bottom: 2px;
}
.input-column {
  flex-grow: 1;
}
.input-column-2 {
  flex-grow: 1;
  padding-top: 10px;
}
.input-fieldset {
  flex: 1;
  padding: 10px;
}
.fieldset-padding {
  padding: 10px;
}
//Flex column
.input-vertical {
  flex-direction: column !important;
  margin: 10px;
}
fieldset {
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 10px 14px 12px;
  margin-bottom: 20px;
  legend {
    font-weight: bold;
    color: #333;
    font-size: 14px;
  }
  .label {
    font-size: 14px;
  }
  .cell {
    p {
      margin: 6px 0;
      font-size: 14px;
    }
  }
}
