@import '@/styles/artifacts.scss';













































.heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
}

.editable-registers-heading {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #ff3b00;
}

.editable-registers-sub-heading {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.editable-registers {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.register-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.register-item-value {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.register-item-value-unit {
  margin-left: 10px;
  font-size: 12px;
  min-width: 25px;
  color: #666;
}

.register-item-value-input {
  width: 200px;
  max-height: 30px;
}

.no-registers-message {
  color: #666;
  font-size: 14px;
  text-align: center;
  padding: 16px;
}
