@import '@/styles/artifacts.scss';










































































































































































































































































































































































































.new-table {
  position: relative;
  table {
    width: 100%;
    border-collapse: collapse;
    th,
    td {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      white-space: nowrap;
    }
    .object-name {
      background-color: white;
      border-bottom: 1px solid lightgray;
      td {
        display: flex;
        gap: 6px;
        a {
          display: flex;
          align-items: center;
          line-height: normal;
          img {
            width: 14px;
            height: 14px;
          }
        }
        span {
          font-size: 16px;
          font-weight: bold;
          margin: 0;
          padding: 4px 0;
          cursor: pointer;
        }
      }
    }
    thead {
      tr {
        &.heading {
        }
        &.sub-heading {
          border-bottom: 1px solid lightgray;
          border-top: 1px solid lightgray;
          th {
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
    }
    tbody {
      tr {
        &:nth-child(even).metric-values {
          background-color: rgba(230, 0, 0, 0.1);
        }
        .alarm-status {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 20px;
            margin-left: 10px;
          }
        }
      }
      td {
        text-align: center;
        &:nth-child(1) {
          min-width: 200px;
          max-width: 200px;
          width: 200px;
        }
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          min-width: 100px;
          max-width: 100px;
          width: 100px;
        }
      }
    }
  }
  img.ui-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  .align-left {
    text-align: left;
  }
  .bg-red {
    background-color: #e60000;
    color: white;
  }
  .bg-black {
    background-color: #333;
    color: white;
  }
}

.context-menu {
  position: fixed;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;

  .context-menu-item {
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}
