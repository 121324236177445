@import '@/styles/artifacts.scss';























































































































































































































































































































































































































































































































































































































































.project-create-step3 {
  display: grid;
  grid-template-columns: 290px 310px 1fr;
  padding: 14px;
  overflow: hidden;
  &.four-column {
    grid-template-columns: 250px 260px 2fr 255px;
  }
  .bold {
    font-weight: bold;
  }
  > section {
    padding: 0 20px 20px 20px;
    h3 {
      margin: 0;
      font-weight: normal;
      font-size: 14px;
    }
    > p {
      margin: 2px 0 20px;
      font-size: 14px;
    }
    &:nth-child(1) {
      padding-left: 0;
    }
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      border-left: 2px solid lightgray;
      border-right: 2px solid lightgray;
    }
    &.select-liegenschaften {
      padding-right: 0;
      > h3 {
        font-weight: bold;
        margin-bottom: 6px;
      }
    }
    .usecase-type {
      width: 265px;
    }
    .main-service {
      max-width: 230px;
    }
  }
  .input-text,
  .input-select {
    height: 36px !important;
    margin-bottom: 6px;
    .vs__selected {
      padding: 3px 0 0 3px;
    }
  }
  fieldset {
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 10px 14px 12px;
    margin-bottom: 20px;
    flex: 1;
    max-width: 100%;
    min-width: 0;
    max-height: fit-content;
    legend {
      font-weight: bold;
      color: #333;
      font-size: 14px;
    }
    .label {
      font-size: 14px;
    }
    .cell {
      p {
        margin: 6px 0;
        font-size: 14px;
      }
    }
    &.selection-products {
      .usecase-type {
        flex-basis: auto !important;
        width: 34%;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: right;
    margin-top: 24px;
  }
}
.step-container {
  min-height: 68dvh;
}
.details-form-heading {
  display: flex;
  justify-content: space-between;
}
.details-heading {
  font-weight: bold !important;
  margin-bottom: 6px !important;
}
.close-button {
  background: transparent;
  border: none;
}
.top-product-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  h3 {
    margin: 0;
    font-weight: normal;
    font-size: 14px;
  }
  p {
    margin: 2px 0 20px;
    font-size: 14px;
  }
}
