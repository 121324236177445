@import '@/styles/artifacts.scss';









































































































































































































































































































































































































































































// Display items in a row and center them vertically
.page-header-container {
  padding: 10px !important;
  display: flex;
  flex-flow: row;
  align-items: center; // Changed to center
  justify-content: space-between;
}
// Separate the divs to either end of the container
.page-sub-container {
  display: flex;
  justify-content: space-between;
}
.back-arrow {
  display: block;
  cursor: pointer;
}
.page-header-text {
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 10px;
}
.container {
  display: flex;
  flex-wrap: wrap;
}

.col {
  flex: 1;
  padding: 1rem; // Adjust padding as needed
}

.col-1-5 {
  flex: 1.5;
  padding: 1rem; // Adjust padding as needed
}

.col-1-3 {
  flex: 0.75;
  padding: 1rem; // Adjust padding as needed
}

.col-2-3 {
  flex: 3;
  // Adjust padding as needed
  padding: 0 1rem 1rem;
}
// Footer must stick to bottom of the page
.association-footer {
  display: flex;
  justify-content: end;
  align-items: center;
}
.step-container {
  min-height: 74dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.right-gray-border {
  border-right: 2px solid colorVodafone(light-grey);
}
.padding-y-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.confirmation-modal-wrapper {
  min-width: 98dvw;
  min-height: 85dvh;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  margin: 0;
}
.close-button {
  background: transparent;
  border: none;
}
.content {
  display: flex;
  flex-direction: column;
}
