<template>
  <div class="modal-overlay">
    <ui-modal ref="modal" :visible="visible" no-close-on-backdrop>
      <ui-flex class="confirmation-modal-wrapper">
        <div class="header">
          <ui-clickable class="close-button" tag="button" @click="hide">
            <img src="./close.svg" alt="Schließen" />
          </ui-clickable>
        </div>
        <div class="content">
          <p>{{ message }}</p>
        </div>
        <div class="actions">
          <ui-wizard-button @click="confirm">Standortliste ändern</ui-wizard-button>
        </div>
      </ui-flex>
    </ui-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'ProjectBuildingEditConfirmationModal',
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const visible = ref(true);
    const modal = ref(null);

    const show = (): void => {
      visible.value = true;
      emit('show');
    };

    const hide = (): void => {
      visible.value = false;
      emit('hide');
    };

    const confirm = (): void => {
      emit('confirm');
      hide();
    };

    return {
      visible,
      modal,
      show,
      hide,
      confirm,
    };
  },
});
</script>

<style scoped>
.confirmation-modal-wrapper {
  padding: 20px;
  max-width: 540px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-end;
  min-width: 540px;
}

.close-button {
  background: transparent;
  border: none;
}
.content {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-width: 540px;
}
</style>
