@import '@/styles/artifacts.scss';















































































































































































#alert-audit-log-modal {
  .modal-content {
    min-width: 92dvw;
    padding: 16px;
    background-color: #f4f4f4;
    .header {
      position: relative;
      margin-bottom: 16px;
      h2 {
        margin: 0;
      }
      p {
        margin: 4px 0 0;
      }
      .close-button {
        background-color: transparent;
        border: none;
        position: absolute;
        top: 24px;
        right: 20px;
      }
    }
  }
}
