@import '@/styles/artifacts.scss';





















































































































































































































































































































































































































.measurements-wrapper {
  margin-top: 20px;
  padding: 10px;
  gap: 15px;

  .panel-container {
    margin: -15px -15px auto;
  }
}

.divider {
  width: 100%;
  height: 2px;
  background-color: #ddd;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tab-content {
  margin: 15px auto;
}

.filter-panel {
  .label {
    color: #e60000;
    font-size: 12px;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 14px;
  .icon-advanced-view {
    img {
      width: 20px;
      height: 20px;
    }
  }
}
