@import '@/styles/artifacts.scss';


















































































































































































































































































































































































































































































































































































































































































.page-header {
  margin-bottom: 10px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px !important;
}
.project-header {
  display: flex;
  flex-flow: row;
  align-items: center;
}
.page-header-text {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 10px;
  cursor: pointer;
}
.page-header-subtext {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 10px;
}
.project-items-container {
  margin-top: 10px;
  min-height: 70dvh;
}
.filter-flex-box {
  flex-grow: 1;
}
.back-arrow {
  display: block;
  cursor: pointer;
}
.filter-panel {
  padding: 0 10px !important;
}
.cancelled-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  flex-grow: 1;
}
