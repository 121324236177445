@import '@/styles/artifacts.scss';


















































































































































































.title {
  font-size: fontSize(fs-150);
}

.description {
  font-size: fontSize(fs-100);
}

.container {
  width: 70%;
  font-size: fontSize(fs-100);

  @media (max-width: 1440px) {
    width: 100%;
  }
}
