@import '@/styles/artifacts.scss';




















































































































































































































.all-selected-message {
  margin-top: 5px;
}

.search-field {
  max-width: 300px;
  max-height: 35px;
}
.count {
  margin-left: 5px;
  color: gray;
}
.filter {
  display: flex;
  padding-bottom: 5px;
  align-items: baseline;
}
.list-buttons {
  margin-left: 5px;
}
.bottom-gray-border {
  border-bottom: 2px solid colorVodafone(light-grey);
}
.liegenshaft-list {
  list-style-type: none;
  padding: 0;
}

.liegenshaft-list li {
  padding: 5px;
  cursor: pointer;
}

.liegenshaft-list li.active {
  background-color: lightgray;
  color: black;
}
.property-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bolder;
}
.property-list-item:hover {
  background-color: colorVodafone(light-grey);
}
.property-list-item-content {
  display: flex;
  justify-content: start;
}

.status-text {
  color: white;
  margin-left: 10px;
}
.subActive {
  color: colorVodafone(grey);
}
h3 {
  margin: 0 0 0.5rem;
  font-weight: normal;
}
