@import '@/styles/artifacts.scss';








































































































































































































.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.heading-two {
  font-size: large;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.search-field {
  max-width: 400px;
  max-height: 35px;
}
.imported-buildings {
  min-height: 40dvh;
  margin-top: 10px;
  overflow: auto;
  display: flex;
  justify-content: space-between;
}
.building-column {
  width: calc(25% - 10px);
}
.count {
  margin-left: 5px;
  color: gray;
}
.flat-p {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 10px;
}
.filter {
  display: flex;
  padding-bottom: 5px;
  align-items: baseline;
}
.list-buttons {
  margin-left: 5px;
}
.bottom-gray-border {
  border-bottom: 2px solid colorVodafone(light-grey);
}
