@import '@/styles/artifacts.scss';
















































































































































































































































































































































































.measurements-wrapper {
  margin-top: 20px;
  padding: 10px;
  gap: 15px;

  .panel-container {
    margin: -15px -15px auto;
  }
}

.divider {
  width: 100%;
  height: 2px;
  background-color: #ddd;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tab-content {
  margin: 15px auto;
}
