@import '@/styles/artifacts.scss';















































































































































































































































































































.container {
  display: flex;
  flex-wrap: wrap;
  height: 68dvh;
}

.col {
  flex: 1;
  padding: 1rem; // Adjust padding as needed
}

.col-1-3 {
  flex: 0.75;
  padding: 1rem; // Adjust padding as needed
  max-width: 20%;
}

.col-2-3 {
  flex: 3;
  // Adjust padding as needed
  padding: 0 1rem 1rem;
  max-width: 50%;
}

.right-gray-border {
  border-right: 2px solid colorVodafone(light-grey);
}
// Footer must stick to bottom of the page
.association-footer {
  display: flex;
  justify-content: end;
  align-items: center;
}
.step-container {
  min-height: 74dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.column-header {
  margin-top: 0;
  margin-bottom: 2px;
}
.margin-left {
  margin-left: 5px;
}
.input-horizontal {
  display: flex;
}
.input-flex {
  flex: 1;
}
