@import '@/styles/artifacts.scss';





















































































































.text-light {
  color: colorVodafone(grey);
}
