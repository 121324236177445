@import '@/styles/artifacts.scss';





























































































































































.no-padding-x {
  padding-left: 0;
  padding-right: 0;
}
.settings__inputs {
  height: 2.2rem !important;
  ::v-deep .vs__selected {
    padding-top: 0px !important;
  }
}
.vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.disabled-settings-link {
  color: colorVodafone(dark-grey);
}
