@import '@/styles/artifacts.scss';





















































































































.temperature-measurement {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: colorVodafone(dark-grey);
  padding: 15px;

  &__title {
    min-height: 105px;
    font-size: fontSize(fs-150);
    text-align: center;
  }

  &__content {
    flex-grow: 1;
    min-height: 200px;
    max-height: 200px;

    ::v-deep .value {
      margin-top: 3%;
      font-size: fontSize(fs-300);
      color: colorVodafone(vodafone-red);
      fill: colorVodafone(vodafone-red);
    }

    &__text {
      font-size: fontSize(fs-150);
    }
  }

  &__latest-metrics {
    text-align: center;
  }
}

.space {
  padding-right: 8px;
}
