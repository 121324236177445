@import '@/styles/artifacts.scss';















































































































































































































































































.imported-buildings {
  min-height: 40dvh;
  margin-top: 10px;
  overflow: auto;
  display: flex;
  justify-content: space-between;
}
.column-header {
  margin-top: 0;
  margin-bottom: 2px;
}
.import-form {
  display: flex;
  flex-flow: row;
  align-items: center;
}
.building-column {
  width: calc(25% - 10px);
}
.no-data-message {
  margin-top: 10px;
}
