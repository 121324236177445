@import '@/styles/artifacts.scss';






























































.customer-selection {
  margin: 0 -10px;
}
