@import '@/styles/artifacts.scss';














































































































































































































































































































































.hs-advanced-view {
  margin: 0 10px;
  width: 100%;
  .search-query-field {
    padding: 0;
    max-width: 400px;
    margin-top: -64px;
    margin-bottom: 12px;
  }
  .table-wrapper {
    overflow: auto;
  }
  .bg-red {
    background-color: #e60000;
    color: white;
  }
  .bg-black {
    background-color: #333;
    color: white;
  }
  .form-message {
    margin-top: 10px;
  }
  .filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      font-weight: normal;
      margin-right: 10px;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      gap: 14px;
      li {
        font-size: 14px;
        text-align: center;
        border-radius: 6px;
        padding: 4px 8px;
        min-width: 100px;
        background: rgba(0, 0, 0, 0.08);
        cursor: pointer;
        &.active {
          background: red;
          color: white;
        }
      }
    }
  }
  .expand-collapse {
    display: flex;
    padding-right: 4px;
    button {
      display: flex;
      align-items: center;
      border: none;
      background: lightgray;
      padding: 8px 12px;
      border-radius: 4px;
      cursor: pointer;
      margin-left: 8px;
      text-transform: uppercase;
      img {
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }
    }
  }
  .pointer {
    cursor: pointer;
    font-size: 16px;
  }
}
