@import '@/styles/artifacts.scss';



















































































































































.page-header-text {
  margin: 0 10px;
}
.audit-log-tabs {
  margin-top: 10px;
}
.filter-holder {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.filter-selects {
  flex: 2;
  padding: 10px;
}
.filter-panel {
  padding: 0 !important;
}
.app-header-icon {
  margin-left: 10px;
}
.back-arrow {
  display: block;
  cursor: pointer;
}
