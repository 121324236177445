@import '@/styles/artifacts.scss';























































































































































































































































































































































.page-header-text {
  margin: 0 10px;
}
.import-form {
  display: flex;
  flex-flow: row;
  align-items: center;
}
.filter-holder {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.filter-selects {
  flex: 2;
}
.project-list-table {
  margin-top: 10px;
  min-height: 78dvh;
}
.filter-panel {
  padding: 0 !important;
}
.app-header-icon {
  margin-left: 10px;
}
//For the popup
// Container should have white background and should be compact (small padding)
.property-popup-container {
  display: block !important;
  z-index: 10000;
  background-color: white;
  padding: 5px;
  #p {
    margin: 0;
  }
}
