<template>
  <div class="modal-overlay">
    <ui-modal ref="modal" :visible="visible" no-close-on-backdrop>
      <div class="confirmation-modal-wrapper-importer-feedback">
        <div class="header">
          <strong>{{ title }}</strong>
          <ui-clickable class="close-button" tag="button" @click="hide">
            <img src="../../../enterprise-project-details/close.svg" alt="Schließen" />
          </ui-clickable>
        </div>
        <div class="content">
          <!-- List errors here -->
          <div v-if="errors && errors.length > 0">
            <Table :rows="errors" :columns="columns" :hidden-columns="[]" :sort-keys.sync="sortKeys" :render-slices="3">
              <template #row="{ row }">
                {{ row && row.row ? row.row : 'File' }}
              </template>
            </Table>
          </div>
          <div v-if="!errors || errors.length === 0">File uploaded successfully</div>
        </div>

        <div class="actions">
          <ui-wizard-button @click="confirm">Ich verstehe</ui-wizard-button>
        </div>
      </div>
    </ui-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from '@vue/composition-api';
import Table from '@/features/ui/table/Table.global.vue';
import { Order } from '@/types/iot-portal';

export default defineComponent({
  name: 'ConfirmationModal',
  components: { Table },
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      required: false,
    },
  },
  setup(props, { emit }) {
    const visible = ref(false);
    const modal = ref(null);

    const show = (): void => {
      visible.value = true;
      emit('show');
    };

    const hide = (): void => {
      visible.value = false;
      emit('hide');
    };

    const confirm = (): void => {
      visible.value = false;
      emit('confirm');
    };

    return {
      visible,
      modal,
      show,
      hide,
      confirm,
    };
  },
  data() {
    return {
      columns: [
        { name: 'row', label: 'Row', sortable: true, width: 100 },
        { name: 'error', width: 500 },
      ],
      sortKeys: [
        {
          key: 'row',
          direction: Order.ASC,
        },
      ],
    };
  },
});
</script>

<style scoped>
.confirmation-modal-wrapper-importer-feedback {
  padding: 20px;
  min-width: 540px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 540px;
}

.close-button {
  background: transparent;
  border: none;
}
.content {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-width: 540px;
}
</style>
