@import '@/styles/artifacts.scss';












































































































































































































































































































































































































































































































#heating-systems-metadata-modal {
  .modal-content {
    min-width: 92dvw;
    padding: 16px;
    background-color: #f4f4f4;
    .header {
      position: relative;
      margin-bottom: 16px;
      h2 {
        margin: 0;
      }
      p {
        margin: 4px 0 0;
      }
      .close-button {
        background-color: transparent;
        border: none;
        position: absolute;
        top: 24px;
        right: 20px;
      }
      .btn-audit-view {
        background-color: transparent;
        border: none;
        position: absolute;
        right: 16px;
        top: 48px;
        img {
          width: 26px;
        }
      }
    }
    .wrapper {
      section {
        &.filters {
          display: flex;
          gap: 20px;
          margin-bottom: 12px;
          width: 600px;
          .cell {
            padding: 0;
            .label {
              color: #e60000;
              font-size: 12px;
            }
            .input-text,
            .input-select {
              width: 100%;
            }
          }
        }
        &.content {
          display: grid;
          grid-template-columns: 222px 300px 1fr;
          &.open-apply-settings {
            grid-template-columns: 180px 240px 2fr 1fr;
          }
          .column {
            > h3 {
              color: #e60000;
              margin: 0;
            }
            > p {
              margin: 0 0 24px;
            }
            .heating-systems-list {
              list-style-type: none;
              padding: 0;
              overflow: auto;
              height: 58vh;
              li {
                padding: 4px 6px;
                border-radius: 2px;
                cursor: pointer;
                &.active {
                  background-color: rgba(#e60000, 0.9);
                  color: white;
                }
              }
            }
            &.component {
              position: relative;
              margin-left: 20px;
              padding-left: 20px;
              margin-right: 20px;
              padding-right: 20px;
              &::before {
                position: absolute;
                content: '';
                border-left: 2px solid lightgray;
                left: 0;
                top: 60px;
                bottom: 0;
              }
              &::after {
                position: absolute;
                content: '';
                border-right: 2px solid lightgray;
                right: 0;
                top: 60px;
                bottom: 0;
              }
            }
          }
        }
      }
    }
  }
  .form-message {
    width: max-content;
  }
}
