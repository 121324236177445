@import '@/styles/artifacts.scss';



























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.metadata-form {
  > h3 {
    span {
      font-weight: normal;
    }
  }
  form {
    .ui-input-field {
      display: grid;
      grid-template-columns: auto auto 1fr;
      margin-bottom: 6px;
      label {
        font-size: 1rem;
        display: inline-block;
        width: 180px;
        margin-right: 10px;
      }
      .input-text {
        height: 38px !important;
        width: 124px;
      }
      .v-select {
        width: 124px;
        height: 38px;
        .vs__selected {
          padding: 4px 6px;
        }
        input {
          margin: 0;
        }
      }
      > span {
        margin-left: 8px;
      }
    }
    .temperature-entries {
      display: grid;
      grid-template-columns: 238px 1fr;
      > section {
        .field-wrapper {
          display: grid;
          grid-template-columns: 180px 180px;
          gap: 10px;
          width: fit-content;
          position: relative;
          .field {
            .input-text {
              height: 38px !important;
              width: 100%;
            }
          }
          .remove-item {
            position: absolute;
            right: -26px;
            bottom: 10px;
            font-size: 1rem;
            font-weight: bold;
            color: red;
            cursor: pointer;
          }
        }
        button {
          margin: 8px 0;
          border: none;
          background-color: transparent;
          color: red;
          cursor: pointer;
        }
      }
    }
  }
  .btn-save-form {
    display: flex;
    justify-content: end;
  }
  fieldset {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 0 14px;
  }
  .divider {
    width: 100%;
    border-bottom: 1px solid lightgray;
    margin: 1.2rem 0;
  }
  &.BUFFER {
    form {
      .input-text,
      .v-select {
        width: 216px;
      }
    }
  }
  &.TELE_HEATING {
    form {
      label {
        width: 266px;
      }
      .input-text,
      .v-select {
        width: 270px;
      }
      .temperature-entries {
        grid-template-columns: 276px 1fr;
        .field-wrapper {
          width: 270px;
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
  &.WARM_HEATING {
    form {
      label {
        width: 254px;
      }
      .input-text,
      .v-select {
        width: 208px;
      }
    }
  }
  &.HEATING_CIRCUIT {
    form {
      label {
        width: 334px;
      }
      .input-text,
      .v-select {
        width: 266px;
      }
      .temperature-entries {
        grid-template-columns: 344px 1fr;
        .field-wrapper {
          grid-template-columns: 1fr 1fr;
          width: 266px;
        }
      }
    }
  }
}
