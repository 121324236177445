@import '@/styles/artifacts.scss';







































































































































































































































.page-header-text {
  margin: 0 10px;
}
.filter-holder {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.filter-selects {
  flex: 2;
  padding: 10px;
}
.audit-log-panel {
  margin-top: 10px;
  padding: 10px;
}
.app-header-icon {
  margin-left: 10px;
}
.building-select {
  //max-height: 30px;
}
.building-select-cell {
  padding: 0px !important;
}
