@import '@/styles/artifacts.scss';




























































































































.imported-buildings {
  min-height: 50dvh;
  max-height: 50dvh;
  overflow: auto;
  padding-top: 10px;
}
.column-header {
  margin-top: 0;
  margin-bottom: 2px;
}
.import-form {
  display: flex;
  flex-flow: row;
  align-items: center;
}
.selection-heading {
  display: flex;
  justify-content: space-between;
}
