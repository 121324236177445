@import '@/styles/artifacts.scss';


































.title {
  font-size: fontSize(fs-150);
}
.description {
  font-size: fontSize(fs-100);
}
.title-wrapper {
  position: relative;
  button {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 8px;
    top: 6px;
    img {
      width: 26px;
    }
  }
}
