@import '@/styles/artifacts.scss';























































.project-list-container {
  max-height: 60dvh;
  min-height: 60dvh;
}
