@import '@/styles/artifacts.scss';




































































































































































































































































































































































































































.title {
  font-size: fontSize(fs-150);
}
.sub-title {
  font-size: fontSize(fs-100);
  font-weight: fontWeight(regular-bold);
}
.description {
  font-size: fontSize(fs-100);
  text-align: justify;
}
.label {
  font-size: 16px;
}

.input-date.temperature__inputs ::v-deep .trigger {
  .ui-clickable.box {
    height: 2.2rem !important;
    .date {
      padding: 5px 0px 5px 5px;
    }
    .clear,
    .calendar {
      padding: 8px 5px;
    }
  }
}
.temperature__inputs {
  height: 2.2rem !important;
}
.input__area-down {
  &-col {
    justify-content: flex-end;
  }
  justify-content: center;
  align-items: flex-end;
}
.no-padding-y {
  padding-top: 0;
  padding-bottom: 0;
}
.no-padding-right {
  padding-right: 0;
}
.no-padding-left {
  padding-left: 0;
}
.alert-settings {
  &__gap {
    margin-bottom: 40px;
  }
}
.month-picker ::v-deep a {
  input {
    width: 140px;
    height: 38px;
  }
  svg {
    top: 10px !important;
  }
}
