@import '@/styles/artifacts.scss';












































































































































































































.project-list-container {
  max-height: 75dvh;
}
.status-cell {
  font-weight: bolder;
}
