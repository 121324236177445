@import '@/styles/artifacts.scss';

















































































































































































.container {
  box-sizing: border-box;
  border: 7px solid;
  border-color: colorVodafone(light-grey);
  border-radius: 50%;
  height: 100%;
  width: 100%;
  padding: 10px 10px;
  text-align: center;

  .value {
    fill: black;
    font-size: 14px;
  }
}
