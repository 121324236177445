@import '@/styles/artifacts.scss';






















































































































































































































































































































































































































































































































































































#advanced-view-graph-modal {
  .modal-content {
    min-width: 92dvw;
    padding: 16px;
    background-color: #f4f4f4;
    .header {
      position: relative;
      margin-bottom: 16px;
      h2 {
        margin: 0;
      }
      .object-name {
        margin: 4px 0 0;
        font-size: 1.1rem;
        color: rgba(0, 0, 0, 0.5);
        font-weight: 600;
      }
      .close-button {
        background-color: transparent;
        border: none;
        position: absolute;
        top: 24px;
        right: 20px;
      }
    }
    .sub-header {
      .cell {
        font-weight: bold;
        font-size: 16px;
      }
    }
    .chart-style-switcher {
      padding-top: 15px;
      padding-right: 15px;
    }
    .chart {
      width: 100%;
      height: 100%;
    }
  }
  .components {
    display: flex;
    gap: 8px;
    justify-content: center;
    span {
      background: lightgray;
      padding: 4px 8px;
      border-radius: 4px;
      cursor: pointer;
      &.active {
        background: red;
        color: white;
      }
    }
  }
}
