@import '@/styles/artifacts.scss';
























































































































































































































#edit-metrics-modal {
  .modal-content {
    min-width: 92dvw;
    padding: 16px;
    background-color: #f4f4f4;
    .header {
      position: relative;
      margin-bottom: 16px;
      min-width: 80dvw;
      min-height: 70vh;
      .header-row {
        display: flex;
        justify-content: flex-end; /* Aligns the close button to the right */
        align-items: center;
      }
      h2 {
        margin: 0;
      }
      p {
        margin: 4px 0 0;
      }
      .close-button {
        background-color: transparent;
        border: none;
      }
    }
  }
}
.tabs {
  display: flex;
  border-bottom: 1px solid #ccc;
  .space {
    padding: 10px 0;
  }
}

.tabs button {
  padding: 10px 20px;
  cursor: pointer;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  outline: none;
}

.tabs button.active {
  border-bottom: 2px solid #007bff;
}

.tab-content {
  padding: 20px;
}

.fieldset-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  .fieldset {
    min-height: 150px;
    .plus-icon {
      float: right;
      border-radius: 50px;
      border: 1px solid;
      cursor: pointer;
    }
  }
}
.clear {
  clear: both;
}

.model-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.container {
  display: grid;
  column-gap: 15px;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
}

.container > div:first-child {
  border-right: 1px solid black;
  padding: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table,
th,
td {
  border: none;
}

th,
td {
  padding: 8px;
  text-align: left;
}

.draggable-row {
  cursor: move;
}

#div1 {
  width: 350px;
  height: 70px;
  padding: 10px;
  border: 1px solid #aaaaaa;
}
