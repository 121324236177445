@import '@/styles/artifacts.scss';




















.common-measurement {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: colorVodafone(dark-grey);
  padding: 15px;

  &__title {
    min-height: 105px;
    font-size: fontSize(fs-150);
    text-align: left;
  }

  &__content {
    flex-grow: 1;
    text-align: right;

    &__value {
      font-size: fontSize(fs-400);
      color: colorVodafone(vodafone-red);
    }

    &__text {
      font-size: fontSize(fs-150);
    }
  }
}
