@import '@/styles/artifacts.scss';
























































































































.metadata-apply-settings {
  .search-objects {
    height: 38px !important;
    margin-bottom: 10px;
  }
  .actions {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-evenly;
    gap: 20px;
    button {
      width: 100%;
    }
  }
}
