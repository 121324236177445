@import '@/styles/artifacts.scss';









































































































































.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.count {
  font-size: 0.8em;
  color: gray;
}

.search-field {
  margin-bottom: 10px;
}

.liegenshaft-list {
  list-style-type: none;
  padding: 0;
}

.liegenshaft-list li {
  padding: 5px;
  cursor: pointer;
}

.liegenshaft-list li.active {
  background-color: lightgray;
  color: black;
}
.liegenshaft-heading {
  margin-bottom: 1px;
}
.property-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bolder;
}

.status-text {
  color: white;
  margin-left: 10px;
}
.subActive {
  color: colorVodafone(grey);
}
