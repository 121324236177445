@import '@/styles/artifacts.scss';









































































































.copy-registers-modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
  &.open {
    display: block;
  }
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    width: fit-content;
    animation-name: animatetop;
    animation-duration: 0.3s;
    .close {
      color: #aaa;
      float: right;
      font-size: 34px;
      line-height: normal;
      margin-top: -10px;
      font-weight: bold;
      transition: all 0.3s;
      &:hover,
      &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
      }
    }
    h3 {
      margin: 0;
    }
    .actions {
      margin-top: 24px;
      display: flex;
      justify-content: flex-end;
    }
  }
  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
}
.product-label {
  width: 150px;
  font-weight: normal;
}
.bold {
  font-weight: bold;
}
.search-heating-system {
  text-align: left;
  margin-top: 16px;
  margin-bottom: 16px;
}
