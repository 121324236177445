@import '@/styles/artifacts.scss';

























































































































































































































































































































































































.column-header {
  margin-top: 0;
  margin-bottom: 2px;
}
.loaded-customer-number-label {
  display: block;
  color: black;
  font-size: 1.286rem;
  line-height: 1.5;
  margin-right: 10px;
}
.single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.custom-label-checkbox {
  display: flex;
  align-items: baseline;
}
.error-label {
  color: #e60000;
}
