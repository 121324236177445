@import '@/styles/artifacts.scss';

















































































































.no-padding-y {
  padding-top: 0;
  padding-bottom: 0;
}
.no-padding-x {
  padding-left: 0;
  padding-right: 0;
}
.settings__inputs {
  height: 2.2rem !important;
  ::v-deep .vs__selected {
    padding-top: 0px !important;
  }
}
.vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bottom-gap {
  padding-bottom: 24px;
}
