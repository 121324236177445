@import '@/styles/artifacts.scss';


































.dhw-temperature-measurements {
  height: 305px;
}
