@import '@/styles/artifacts.scss';


































































































































































































































































































































































































































































.title {
  font-size: fontSize(fs-150);
}

.description {
  font-size: fontSize(fs-100);
}

.settings__inputs {
  height: 2.2rem !important;

  ::v-deep .vs__selected {
    padding-top: 2px !important;
  }
}

.container {
  font-size: fontSize(fs-100);
}

.vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
