@import '@/styles/artifacts.scss';





















































































































































































































































.page-header {
  margin-bottom: 10px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px !important;
}
.project-header {
  display: flex;
  flex-flow: row;
  align-items: center;
}
.page-header-text {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 10px;
}
.page-header-subtext {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 10px;
}
.project-items-container {
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 70dvh;
}
.filter-flex-box {
  flex-grow: 1;
}
.back-arrow {
  display: block;
  cursor: pointer;
}
.filter-panel {
  padding: 0 10px !important;
}
.step-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.container {
  min-height: 62dvh;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.col {
  flex: 1;
  padding: 1rem; // Adjust padding as needed
}
.col-1-3 {
  flex: 0.5;
  padding: 1rem; // Adjust padding as needed
}
.right-gray-border {
  border-right: 1px solid #e0e0e0;
}
.association-footer {
  padding: 16px;
  border-top: 1px solid #e0e0e0;
  margin-top: auto;
}

.button-container {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
}
