@import '@/styles/artifacts.scss';








































































.status-blue {
  color: blue;
}
.status-red {
  color: red;
}
.status-orange {
  color: orange;
}
.status-green {
  color: green;
}
