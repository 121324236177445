<template>
  <div>
    <ButtonWithIcon @click="show">Kopieren</ButtonWithIcon>
    <div class="modal-overlay">
      <ui-modal ref="modal" :visible="visible" no-close-on-backdrop>
        <Flex class="confirmation-modal-wrapper">
          <div class="header">
            <strong>Projekt kopieren</strong>
            <ui-clickable class="close-button" tag="button" @click="hide">
              <img src="../close.svg" alt="Schließen" />
            </ui-clickable>
          </div>
          <div class="content">
            <p>
              Möchten Sie die grundlegenden Daten in ein neues Projekt überführen? Sie können im Nachgang Änderungen an
              den Eingaben vornehmen, sofern dies erfoderlich ist.
            </p>
          </div>

          <p class="copy-type-select-p">Bitte wählen Sie einen Grund aus der Liste aus:</p>
          <InputSelect
            v-model="copyType"
            class="copy-type-select"
            :reduce="({ value }) => value"
            :options="copyOptions"
            :data-element-id="`project-copy-type-select`"
          />
          <div class="actions">
            <WizardButton :disabled="!copyType" :busy="saving" @click="copyProject">Projekt anlegen</WizardButton>
          </div>
        </Flex>
      </ui-modal>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from '@vue/composition-api';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import InputSelect from '@/features/ui/inputs/InputSelect.global.vue';
import { EnterpriseProjectCopyOption } from '@/features/app-enterprise-project/views/enterprise-project-details/project-details-constants';
import Flex from '@/features/ui/layout/Flex.global.vue';
import ButtonWithIcon from '@/components/clickables/Button.global.vue';
import { mapActions } from 'vuex';
import copyProject from '@/features/app-enterprise-project/views/enterprise-project-details/copy-project.gql';

export default defineComponent({
  name: 'ConfirmationModalButton',
  components: {
    Flex,
    InputSelect,
    WizardButton,
    ButtonWithIcon,
  },
  props: {
    projectReference: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const visible = ref(false);
    const modal = ref(null);

    const show = (): void => {
      visible.value = true;
      emit('show');
    };

    const hide = (): void => {
      visible.value = false;
      emit('hide');
    };

    const confirm = (): void => {
      visible.value = false;
      emit('confirm');
    };

    return {
      visible,
      modal,
      show,
      hide,
      confirm,
    };
  },
  data() {
    return {
      copyType: null,
      copyOptions: EnterpriseProjectCopyOption,
      saving: false,
    };
  },
  methods: {
    ...mapActions({
      addToastMessages: 'ADD_TOAST_MESSAGES',
    }),
    async copyProject() {
      this.saving = true;
      const { data } = await this.$apollo.mutate({
        mutation: copyProject,
        variables: {
          projectReference: this.projectReference,
          newProjectType: this.copyType,
        },
      });
      await this.addToastMessages({
        duration: 2000,
        messages: [{ text: 'Projekt erfolgreich kopiert', type: 'success' }],
      });
      this.hide();
      this.saving = false;
      // Clear state for create flow
      this.$store.commit('clearState');
      // Navigate to the new project
      if (data.copyProject?.projectReference) {
        await this.$router.push({
          name: 'AppEnterpriseProject/ProjectDetails',
          params: {
            projectReference: data.copyProject?.projectReference,
          },
        });
      }
    },
  },
});
</script>

<style scoped>
.confirmation-modal-wrapper {
  padding: 20px;
  max-width: 540px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 540px;
}

.close-button {
  background: transparent;
  border: none;
}
.content {
  display: flex;
  justify-content: space-between;
}
.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-width: 540px;
  margin-top: 10px;
}
.copy-type-select {
  margin-top: 20px;
  width: 100%;
}
.copy-type-select-p {
  margin-bottom: 0;
}
</style>
