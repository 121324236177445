@import '@/styles/artifacts.scss';


























.header-item {
  color: colorVodafone(vodafone-red);
  font-size: fontSize(fs-50);
}
