@import '@/styles/artifacts.scss';














































.heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
}

.control-type-heading {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #ff3b00;
}

.page-header-text {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.control-type-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.property-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  margin: 2px 0;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(255, 59, 0, 0.1);
  }

  &.active {
    background-color: #ff3b00; // Orange/red color as shown in the image
    color: white;
  }
}

.status-text {
  color: white;
  margin-left: 10px;
  font-size: 12px;
}

.no-control-model-message {
  color: #666;
  font-size: 14px;
  text-align: center;
  padding: 16px;
}

.subActive {
  color: #666;
}
